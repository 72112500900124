import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import axios from "../utils/axios";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { AuthContext, useAuth } from "../utils/auth-context";
import timezones from 'timezones-list';
const carreirLoginValidator = (values) => {
  const errors = {};

  if (values.old_password === "") {
    errors.old_password = "This field is required.";
  } else if (values.old_password.length < 6) {
    errors.old_password = "Old Password is too short.";
  } else if (values.old_password.length > 16) {
    errors.old_password = "Old Password is too long.";
  }

  if (values.new_password === "") {
    errors.new_password = "This field is required.";
  } else if (values.new_password.length < 6) {
    errors.new_password = "New Password is too short.";
  } else if (values.new_password.length > 16) {
    errors.new_password = "New Password is too long.";
  }

  if (values.confirm_password === "") {
    errors.confirm_password = "This field is required.";
  } else if (values.confirm_password.length < 6) {
    errors.confirm_password = "Confirm Password is too short.";
  } else if (values.confirm_password.length > 16) {
    errors.confirm_password = "Confirm Password is too long.";
  } else if (values.new_password != values.confirm_password) {
    errors.confirm_password = "Password match failed.";
  }
  console.log(errors)
  return errors;
};
const ChangePassword = () => {
  const { token } = useContext(AuthContext)
  console.log(token)
  const history = useHistory();
  return (
    <>
      <div className="p-3 py-5">
        <div
          style={{
            maxWidth: "300px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <Link to="/drivers"
            style={{
              textDecoration: "none",
              position: "absolute",
              top: "20px",
              left: "50px",
            }}>
            <i className="fa-solid fa-arrow-left"></i> Go Back
          </Link>
          <Formik
            initialValues={{ old_password: "", new_password: "", confirm_password: "" }}
            validate={carreirLoginValidator}
            onSubmit={async (values) => {
              await axios
                .post("/companies/chnage-password", {
                  old_password: values.old_password,
                  new_password: values.new_password
                }, {
                  headers: {
                    token
                  }
                })
                .then((resp) => {
                  toast.success('Password Changed')
                  history.push("/carrier-dashboard");
                })
                .catch((err) => {
                  toast.error(err.response?.data?.message);
                });
            }}
          >
            {({ handleSubmit, errors, getFieldProps }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Old Password"
                    className="form-ctrl"
                    {...getFieldProps("old_password")}
                  />
                  {errors.old_password && (
                    <small className="text-danger">{errors.old_password}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="New Password"
                    className="form-ctrl"
                    {...getFieldProps("new_password")}
                  />
                  {errors.new_password && (
                    <small className="text-danger">{errors.new_password}</small>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    className="form-ctrl"
                    {...getFieldProps("confirm_password")}
                  />
                  {errors.confirm_password && (
                    <small className="text-danger">{errors.confirm_password}</small>
                  )}
                </FormGroup>

                <div className="d-flex justify-content-center">
                  <Button className="btn_light" type="submit">
                    Change password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;