import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Spinner } from "reactstrap";
import LoaderItem from "../components/LoaderItem";
import TrackerDivider from "../components/Tracker/TrackerDivider";
import TrackerItem from "../components/Tracker/TrackerItem";
import axios, { url } from "../utils/axios";

const trackerInputValidator = (values) => {
  const errors = {};
  const { id } = values;

  if (id === "") {
    errors.id = "This field is required.";
  }

  return errors;
};

export const statuses = [
  "Rate sheet received & accepted",
  "Driving to the shipper",
  "Arrived at the shipper",
  "Checked in and waiting for a dock",
  "Trailer at the dock",
  "Loading in process",
  "Loading complete",
  "Driving to the reciever",
  "Arrived at the reciever",
  "Checked in and waiting for a dock",
  "Trailer at the dock",
  "Unloading in process",
  "Unloading complete",
];

const Home = () => {
  useEffect(() => {
    const myElement = document.getElementById('iframe')
    if (window.innerWidth >= 660) {
      myElement.style.width = "600px"
    } else {
      myElement.style.width = '320px';
    }
    const handleresize = () => {
      if (window.innerWidth >= 660) {
        myElement.style.width = "600px"
      } else {
        myElement.style.width = '320px';
      }
    }
    window.addEventListener('resize', handleresize)

  }, [])
  const videoOptions = {
    // height: '390',
    // width: '640',
    maxWidth: '100%',
    margin: '50px 20px',
    playerVars: {
      // YouTube video parameters (e.g., autoplay, controls, etc.)
      autoplay: 0,
    },
  };

  // YouTube video ID (replace with your video's ID)
  const videoId = '73ktR5oFsbc';
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaders, setLoaders] = useState([]);

  const history = useHistory();

  if (loaded) {
    history.push("/" + loaded.name + "/" + loaded.loadNumber);
    return false;
  }
  return (
    <>
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px'
      }}>
        <img src="img/0.png" style={{ width: '100px' }} />
        <a href="https://www.truckerfox.com/contact" target="_blank" style={{ fontWeight: 'bold', color: 'black', textDecoration: 'none' }}>Contact Us</a>
      </div>
      <div className="p-3 py-5">
        <div
          style={{
            maxWidth: 600,
            width: "100%",
            margin: "0px auto",
          }}
        >
          <h3 className="text-center mb-5" style={{ fontWeight: "bold" }}>
            Track the Progress of a Load
          </h3>
          <div className="tracking-search-form mb-5">
            <Formik
              initialValues={{ company_name: "", id: "" }}
              validate={trackerInputValidator}
              onSubmit={async (values) => {
                setLoading(true);
                await axios
                  .post("/courier/search", {
                    company_name: values.company_name,
                    load_number: values.id,
                  })
                  .then((resp) => {
                    setLoading(false);
                    setError(null);
                    if (resp.data.length === 1) {
                      setLoaders([]);
                      setLoaded(resp.data[0]);
                      // setLoaders(resp.data);
                    } else if (resp.data.length > 1) {
                      setLoaded(null);
                      setLoaders(resp.data);
                    } else {
                      setLoaded(null);
                      setLoaders([]);
                      setError("No data found");
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    setError(err?.response?.data?.message || err?.message);
                    setLoaded(null);
                    setLoaders([]);
                  });
              }}
            >
              {({ errors, getFieldProps, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div style={{ width: "100%", position: 'relative' }}>
                      <Input
                        placeholder="Enter Load Number"
                        {...getFieldProps("id")}
                      />
                      <Button type="submit" style={{ position: 'absolute', right: '0px', top: '0px', height: "60px", borderRadius: '30px' }}>
                        {loading ? <Spinner type="border" size="sm" /> : "Track"}
                      </Button>
                    </div>

                  </div>
                  {errors.id && <p className="text-danger ps-2">{errors.id}</p>}
                </Form>
              )}
            </Formik>
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner type="border" color="primary" size="lg" />
            </div>
          ) : loaders.length > 0 ? (
            loaders.map((item, index) => (
              <LoaderItem
                item={item}
                key={index}
                onClick={(id) => {
                  setLoaded(loaders.find((item) => item.id === id));
                  setLoaders([]);
                }}
              />
            ))
          ) : (
            error && (
              <div>
                <p className="text-center text-danger">{error}</p>
              </div>
            )
          )}
          <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
            {/* <Button
              className="btn_light"
              onClick={() => history.push("/admin-login")}
            >
              Admin Login
            </Button> */}
            <Button
              className="btn_light"
              onClick={() => history.push("/carrier-login")}
            >
              Carrier Login
            </Button>
            <Button
              className="btn_light"
              onClick={() => history.push("/carrier-signup")}
            >
              Carrier Signup
            </Button>
            <Button
              className="btn_light"
              onClick={() => window.location.href = "https://truckerfox.com/about"}
            >
              About us
            </Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "100px" }}>
            <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>Track your drivers & freight with ease</h3>
            <p style={{ fontWeight: '500', fontSize: '1.1rem', textAlign: 'center' }}>
              Our technology empowers you to monitor the
              progress of a freight from booking to delivery,
              without ever having to call the driver to ask for
              updates. Sign up to be the first to experience the
              future of logistics.
            </p>
            <h3 style={{ fontWeight: '500', marginTop: '60px' }}>How It Works</h3>
            {/* <img src='img/3.jpg' style={{ maxWidth: '320px' }} /> */}
            <iframe id="iframe" src="https://www.youtube.com/embed/73ktR5oFsbc?autoplay=0&enablejsapi=1&origin=http%3A%2F%2Flocalhost%3A3000&widgetid=13" style={{ padding: '20px', marginTop: '40px', aspectRatio: '1.5', }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px', marginTop: '50px' }}>
              <a href="https://apps.apple.com/pk/app/trucker-fox/id1667169973" target="_blank"><img src="img/1.png" style={{ width: '120px' }} /></a>
              <a href="https://play.google.com/store/apps/details?id=com.rn.app.trucker.fox" target="_blank"><img src="img/2.png" style={{ width: '120px' }} /></a>
            </div>
            {/* <YouTube videoId={videoId} opts={videoOptions} /> */}
            <p style={{ fontWeight: 'bold', maxWidth: '360px', textAlign: 'center', marginTop: '40px' }}>
              Trucker Fox Support helpline: <span className="d-block d-sm-inline">(817) 805-6725</span>
            </p>
          </div>
        </div>
      </div >
    </>
  );
};

export default Home;
