import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup, Input } from "reactstrap";
import axios from "../utils/axios";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import timezones from 'timezones-list';
const carreirLoginValidator = (values) => {
  const errors = {};
  if (values.email === "") {
    errors.email = "This field is required.";
  }
  if (values.name === "") {
    errors.name = "First Name is required.";
  }
  if (values.phone === "") {
    errors.phone = "Phone number is required.";
  }
  if (values.mcnumber === "") {
    errors.mcnumber = "MC Number is required.";
  }
  if (values.password === "") {
    errors.password = "This field is required.";
  } else if (values.password.length < 6) {
    errors.password = "Password is too short.";
  } else if (values.password.length > 16) {
    errors.password = "Password is too long.";
  }
  console.log(errors)
  return errors;
};
const CompanySignup = () => {
  const history = useHistory();
  return (
    <>
      <div className="p-3 py-5">
        <div
          style={{
            maxWidth: "300px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <Link to="/"
            style={{
              textDecoration: "none",
              position: "absolute",
              top: "20px",
              left: "50px",
            }}>
            <i className="fa-solid fa-arrow-left"></i> Go Back
          </Link>
          <Formik
            initialValues={{ email: "", password: "", name: "", phone: "", mcnumber: "" }}
            validate={carreirLoginValidator}
            onSubmit={async (values) => {
              await axios
                .post("/companies/new", {
                  email: values.email,
                  password: values.password,
                  carrierName: values.name,
                  phone: values.phone,
                  mcNumber: values.mcnumber,
                })
                .then((resp) => {
                  history.push("/drivers");
                  toast.success('Carrier created!')
                })
                .catch((err) => {
                  toast.error(err.response?.data?.message);
                });
            }}
          >
            {({ handleSubmit, errors, getFieldProps }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    placeholder="Carrier name"
                    className="form-ctrl"
                    {...getFieldProps("name")}
                  />
                  {errors.name && (
                    <small className="text-danger">{errors.name}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder="Email"
                    className="form-ctrl"
                    {...getFieldProps("email")}
                  />
                  {errors.email && (
                    <small className="text-danger">{errors.email}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder="Phone Number"
                    className="form-ctrl"
                    {...getFieldProps("phone")}
                  />
                  {errors.phone && (
                    <small className="text-danger">{errors.phone}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder="MC Number"
                    className="form-ctrl"
                    {...getFieldProps("mcnumber")}
                  />
                  {errors.mcnumber && (
                    <small className="text-danger">{errors.mcnumber}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Password"
                    className="form-ctrl"
                    {...getFieldProps("password")}
                  />
                  {errors.password && (
                    <small className="text-danger">{errors.password}</small>
                  )}
                </FormGroup>
                <div className="d-flex justify-content-center">
                  <Button className="btn_light" type="submit">
                    Carrier signup
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CompanySignup;