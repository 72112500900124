import React from "react";
import { Badge } from "reactstrap";

const LoaderItem = ({ item, onClick }) => {
  return (
    <div
      style={{
        padding: "10px",
        boxShadow: "0px 0px 5px gray",
        borderRadius: "10px",
        margin: "15px 0px",
      }}
      className="d-flex align-items-center justify-content-between"
    >
      <div>
        <h5 className="mb-0">{item.name}</h5>
        <p className="mb-0">{item.loadNumber}</p>
      </div>
      <Badge color="primary" onClick={() => onClick(item.id)}>
        View Detail &gt;&gt;
      </Badge>
    </div>
  );
};

export default LoaderItem;
