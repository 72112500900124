const helpers = {
  /**
   *
   * @param {string} str A string value to be capitalize
   *
   * @returns A string with capital initial letter
   */
  capitalize: (str) => {
    const str_arr = str?.split(" ");
    const str_arr_2 = str_arr?.map((item) => {
      return item?.charAt(0)?.toUpperCase() + item?.slice(1);
    });
    return str_arr_2?.join(" ");
  },
};

export default helpers;
