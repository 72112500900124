import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup, Input } from "reactstrap";
import axios from "../utils/axios";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useAuth } from "../utils/auth-context";

const carreirLoginValidator = (values) => {
  const errors = {};
  if (values.email === "") {
    errors.email = "This field is required.";
  }

  if (values.password === "") {
    errors.password = "This field is required.";
  } else if (values.password.length < 6) {
    errors.password = "Password is too short.";
  } else if (values.password.length > 16) {
    errors.password = "Password is to long.";
  }

  return errors;
};

const AdminLogin = () => {
  const { login } = useAuth();
  const history = useHistory();

  return (
    <div className="p-3 py-5">
      <div
        style={{
          maxWidth: "300px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <i className="fa-solid fa-arrow-left"></i> Go Back
        </Link>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={carreirLoginValidator}
          onSubmit={async (values) => {
            await axios
              .post("/auth/admin-login", values)
              .then((resp) => {
                login(resp.data);
                history.push("/admin-dashboard");
              })
              .catch((err) => {
                toast.error(err.response?.data?.message);
              });
          }}
        >
          {({ handleSubmit, errors, getFieldProps }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  placeholder="Email"
                  className="form-ctrl"
                  {...getFieldProps("email")}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Password"
                  className="form-ctrl"
                  {...getFieldProps("password")}
                />
                {errors.password && (
                  <small className="text-danger">{errors.password}</small>
                )}
              </FormGroup>
              <div className="d-flex justify-content-center">
                <Button className="btn_light" type="submit">
                  Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;
