import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";

export const AuthContext = createContext({
  token: null,
  user: null,
  isLoggedIn: false,
  login: (data) => { },
  logout: () => { },
});

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || ""
  );
  const [current_plan, set_current_plan] = useState()
  const isLoggedIn = !!token && !!user;
  const login = (data) => {
    setToken(data.token);
    setUser(data);
    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data));
  };
  const logout = () => {
    setToken("");
    setUser("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider value={{ token, user, isLoggedIn, login, logout, current_plan, set_current_plan }}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth };
export default AuthProvider;
