import React from "react";

const TrackerDivider = () => {
  return (
    <div>
      <div style={{ width: 60 }}>
        <p className="text-center mb-0">|</p>
        <p className="text-center mb-0">|</p>
      </div>
    </div>
  );
};

export default TrackerDivider;
