import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../utils/auth-context";
import axios from "../utils/axios";
import { Table } from "reactstrap";

const Drivers = () => {
  const { token, user } = useAuth();
  const history = useHistory();

  const [drivers, setDrivers] = useState([]);

  if (user.role !== "company") {
    history.push("/admin-dashboard");
  }

  useEffect(() => {
    const getDrivers = async () => {
      const resp = await axios
        .get("/driver/" + user?.mcNumber, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((resp) => resp.data);
      setDrivers(resp);
    };

    getDrivers();
  }, []);
  const handle_delete = async (id) => {
    const resp = await axios
      .post("/driver/id", { id, mcNumber: user?.mcNumber }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => resp.data);
    setDrivers(resp)

  }
  return (
    <div className="p-3 py-5">
      <div
        style={{
          maxWidth: "900px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Link
          to="/admin-dashboard"
          style={{
            textDecoration: "none",
            position: "absolute",
            top: "20px",
            left: "50px",
          }}
        >
          <span className="fa-solid fa-arrow-left"></span> Go Back
        </Link>
        <Link
          to="/add_driver"
          style={{
            textDecoration: "none",
            position: "absolute",
            top: "20px",
            right: "50px",
          }}
        >
          Add New Driver
        </Link>
        <h3 className="text-center mb-5" style={{ fontWeight: "bold" }}>
          Drivers
        </h3>
        <Table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Phone</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {drivers?.length > 0 ? (
              drivers.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.name || ""} {item.l_name || ""}
                  </td>
                  <td>{item.email || ""}</td>
                  <td>{item.phone || ""}</td>
                  <td style={{ cursor: 'pointer', color: '#be1f2d' }} onClick={() => { handle_delete(item.id) }}>Delete</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  No driver registered.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Drivers;
