import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, InputGroup } from "reactstrap";
import TrackerDivider from "../components/Tracker/TrackerDivider";
import TrackerItem from "../components/Tracker/TrackerItem";
import axios, { url } from "../utils/axios";
import { statuses } from "./Home";
import { useAuth } from "../utils/auth-context";
import helpers from "../utils/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Load = ({
  match: {
    params: { company, load },
  },
}) => {
  console.log(company, load)
  const [loaded, setLoaded] = useState(null);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  const from = search.get("from");

  const { token } = useAuth();

  useEffect(() => {
    const getLoad = async () => {
      try {
        const res = await axios
          .post("/courier/search_load_web", {
            company_name: company,
            load_number: load,
          })
          .then((resp) => resp.data);
        setLoaded(res);
        console.log('my-response', res)
      } catch (error) {
        setError(error.response?.data?.message || error.message);
      }
    };

    getLoad();
  }, []);

  if (error) {
    return (
      <div>
        <p className="text-center text-danger">{error}</p>
      </div>
    );
  }

  return (
    <div>
      <p className="mb-0 p-3">
        <Link
          to={from ? "/carrier-dashboard" : "/"}
          style={{ textDecoration: "none" }}
        >
          <span className="fa-solid fa-arrow-left"></span> Go Back
        </Link>
      </p>
      <div
        style={{
          padding: "15px 15px",
          maxWidth: 600,
          width: "100%",
          margin: "0px auto",
        }}
      >
        {loaded ? (
          <div className="d-flex flex-md-row flex-column justify-content-between">
            <div>
              {loaded?.couriers
                ?.sort((a, b) => a.status - b.status)
                ?.map((item, index) => (
                  <div key={index}>
                    <TrackerItem
                      text={statuses[item.status - 1]}
                      isCompleted={item.isCompleted}
                      date={moment(item.statusUpdatedAt).format(
                        "MMMM DD, YYYY hh:mm A"
                      )}
                    />
                    {index !== loaded?.couriers?.length - 1 && (
                      <TrackerDivider />
                    )}
                  </div>
                ))}
            </div>
            <div style={{ width: 205 }}>
              <div className="mb-3">
                <h5 style={{ fontWeight: "bold" }}>Broker</h5>
                <p className="mb-0">{loaded.name}</p>
                <p>{loaded.loadNumber}</p>
              </div>
              <div className="mb-3">
                <h5 style={{ fontWeight: "bold" }}>Driver</h5>
                <p className="mb-0">
                  {helpers.capitalize(loaded.user.name)}{" "}
                  {helpers.capitalize(loaded.user.l_name)}
                </p>
                <p className="mb-0">{loaded.user.phone}</p>
              </div>
              {token &&
                <div>
                  {loaded.bols.length === 0 &&
                    <>
                      <h5 style={{ fontWeight: "bold" }}>Download Rate Con</h5>
                      <p className="mb-0">
                        <a
                          href={url + "/api/file/" + loaded.rateCon.document}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {loaded.rateCon.document}{" "}
                          <i className="fa-solid fa-cloud-arrow-down ms-2"></i>
                        </a>
                      </p>
                    </>
                  }
                  {loaded.bols.length > 0 && loaded.bols.map((item, index) => (
                    <>
                      <h5 style={{ fontWeight: "bold" }}>Download BOL / POD</h5>
                      <p className="mb-0" key={index}>
                        <a
                          href={url + "/api/file/" + item.name}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {item.name}{" "}
                          <i className="fa-solid fa-cloud-arrow-down ms-2"></i>
                        </a>
                      </p>
                    </>
                  ))}
                </div>
              }
              <hr />
              <div>
                <InputGroup>
                  <Input
                    value={
                      "https://truckerfox.com/" +
                      loaded.name +
                      "/" +
                      loaded.loadNumber
                    }
                    readOnly
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "https://truckerfox.com/" +
                        loaded.name +
                        "/" +
                        loaded.loadNumber
                      );
                      setCopied(true);
                    }}
                    disabled={copied}
                  >
                    {copied ? "Copied" : "Copy"}
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Load;
