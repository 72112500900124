import AxiosInstance from "axios";

const backendUrl = {
  local: "https://truckerfox.com",
  remote: "https://dev.truckerfox.com",
};

export const url = backendUrl.local;

const axios = AxiosInstance.create({
  baseURL: `${url}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axios;
