import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Load from "./Load";
import CarrierLogin from "./CarrierLogin";
import CarrierDashboard from "./CarrierDashboard";
import { useAuth } from "../utils/auth-context";
import { Redirect } from "react-router-dom";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";
import Drivers from "./Drivers";
import AddDriver from "./AddDriver";
import CompanySignup from "./CompanySignup";
import ChangePassword from "./ChangePassword";
import Upgrade from "./Upgrade";

const Main = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/admin-login">
        {isLoggedIn ? <Redirect to="/admin-dashboard" /> : <AdminLogin />}
      </Route>
      <Route exact path="/carrier-login">
        {isLoggedIn ? <Redirect to="/carrier-dashboard" /> : <CarrierLogin />}
      </Route>
      {/* <Route exact path="/carrier-reset-password">
        {isLoggedIn ? <Redirect to="/carrier-dashboard" /> : <CarrierResetPassword />}
      </Route> */}
      <Route exact path="/carrier-signup">
        {isLoggedIn ? <Redirect to="/carrier-dashboard" /> : <CompanySignup />}
      </Route>
      <Route exact path="/admin-dashboard">
        {isLoggedIn ? <AdminDashboard /> : <Redirect to="/admin-login" />}
      </Route>
      <Route exact path="/upgrade">
        {isLoggedIn ? <Upgrade /> : <Redirect to="/admin-login" />}
      </Route>
      <Route exact path="/carrier-dashboard">
        {isLoggedIn ? <CarrierDashboard /> : <Redirect to="/carrier-login" />}
      </Route>
      <Route exact path="/drivers">
        {isLoggedIn ? <Drivers /> : <Redirect to="/carrier-login" />}
      </Route>
      <Route exact path="/add_driver">
        {isLoggedIn ? <AddDriver /> : <Redirect to="/carrier-login" />}
      </Route>
      <Route exact path="/change-password">
        {isLoggedIn ? <ChangePassword /> : <Redirect to="/change-password" />}
      </Route>

      <Route exact path="/:company/:load" component={Load} />
      <Route
        path="*"
        component={() => (
          <h3
            style={{ width: "100%", textAlign: "center", paddingTop: "50px" }}
          >
            404 | Not Found
          </h3>
        )}
      />
    </Switch>
  );
};

export default Main;
