import { useState } from "react";
import { useAuth } from "../utils/auth-context";
import { Button } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Upgrade = () => {
  const [selected, set_selected] = useState('')
  const { user } = useAuth();
  console.log(user)
  const handleclick = () => {
    switch (selected) {
      case 'Basic Plan':
        window.location.href = "https://buy.stripe.com/test_eVa00p9HAb1a0BGdQQ"
        break;
      case 'Bronze Plan':
        window.location.href = "https://buy.stripe.com/test_5kA14t1b40mwesw5kl"
        break;
      case 'Silver Plan':
        window.location.href = "https://buy.stripe.com/test_28ofZn070gludosfZ0"
        break;
      case 'Gold Plan':
        window.location.href = "https://buy.stripe.com/test_00g28xaLEglu3NSeUX"
        break;
      default:
        break;
    }
  }
  return (
    <>
      {/* <h2 style={{ textAlign: 'center' }}>Upgrade Plan</h2>
      <select
        value={selected}
        onChange={(e) => set_selected(e.target.value)}>
        <option value="">--Select Plan--</option>
        {user.user.plan !== "Basic Plan" && user.user.plan !== "Silver Plan" && user.user.plan !== "Bronze Plan" && user.user.plan !== "Silver Plan" && user.user.plan !== "Gold Plan" &&
          <option value="Basic Plan">Basic Plan</option>
        }
        {user.user.plan !== "Bronze Plan" && user.user.plan !== "Silver Plan" && user.user.plan !== "Gold Plan" &&
          <option value="Bronze Plan">Bronze Plan</option>
        }
        {user.user.plan !== "Silver Plan" && user.user.plan !== "Gold Plan" &&
          <option value="Silver Plan">Silver Plan</option>
        }
        {user.user.plan !== "Gold Plan" &&
          <option value="Gold Plan">Gold Plan</option>
        }
      </select>
      <Button className="btn_light" type="submit" disabled={!selected} onClick={handleclick}>
        Upgrade
      </Button> */}
      <div style={{ margin: '20px' }}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            // position: "absolute",
            // top: "20px",
            // left: "50px",
          }}
        >
          <span className="fa-solid fa-arrow-left"></span> Go Back
        </Link >
      </div>
      <h2 style={{ textAlign: 'center', marginTop: '40px' }}>Upgrade Plan</h2>
      <div
        className="d-flex flex-column flex-lg-row"
        style={{
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          gap: '16px',
          margin: '20px'
        }}
      >
        <div style={{
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
          padding: '20px',
          flex: 1
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h4 style={{}}>Bronze Plan</h4>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: "center",
              // gap: "20px",
              alignItems: 'baseline'
            }}>
              {/* <h5>$</h5> */}
              <p style={{ fontSize: '2.5rem', fontWeight: '300' }}>$29</p>
              <h5>/mo</h5>
            </div>
            <p style={{ fontSize: '14px', marginBottom: '0px', fontWeight: 'bold' }}>100 loads/month</p>
            {/* <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/9AQcQO3dYf8O62cfZ1"}>
              Get started
            </Button> */}

            {/* Test */}
            <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/test_eVa00p9HAb1a0BGdQQ"}>
              Get started
            </Button>
            {/* <p style={{ marginTop: '40px', fontWeight: 'bold' }}>What you get</p> */}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '40px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >100 Loads/Month</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Document Storage</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Drivers</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Users</p>
            </div>
          </div>
        </div>

        <div style={{
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
          padding: '20px',
          flex: 1
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h4 style={{}}>Silver Plan</h4>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: "center",
              // gap: "20px",
              alignItems: 'baseline'
            }}>
              {/* <h5>$</h5> */}
              <p style={{ fontSize: '2.5rem', fontWeight: '300' }}>$49</p>
              <h5>/mo</h5>
            </div>
            <p style={{ fontSize: '14px', marginBottom: '0px', fontWeight: 'bold' }}>300 loads/month</p>
            {/* <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/aEUaIG4i28KqgGQ7sw"}>
              Get started
            </Button> */}
            {/* Test */}

            <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/test_5kA14t1b40mwesw5kl"}>
              Get started
            </Button>
            {/* <p style={{ marginTop: '40px', fontWeight: 'bold' }}>What you get</p> */}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '40px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >300 Loads/Month</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Document Storage</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Drivers</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Users</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Technical Support</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >New Feature Request</p>
            </div>
          </div>
        </div>

        <div style={{
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
          padding: '20px',
          flex: 1
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h4 style={{}}>Gold Plan</h4>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: "center",
              // gap: "20px",
              alignItems: 'baseline'
            }}>
              {/* <h5>$</h5> */}
              <p style={{ fontSize: '2.5rem', fontWeight: '300' }}>$129</p>
              <h5>/mo</h5>
            </div>
            <p style={{ fontSize: '14px', marginBottom: '0px', fontWeight: 'bold' }}>800 loads/month</p>
            <p style={{ fontSize: '14px', marginBottom: '10px', }}>plus $0.3/additional load</p>

            {/* <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/7sI8Ay6qa0dU8ak7sx"} >
              Get started
            </Button> */}

            {/* Test */}

            <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/7sI8Ay6qa0dU8ak7sx"} >
              Get started
            </Button>
            {/* <p style={{ marginTop: '40px', fontWeight: 'bold' }}>What you get</p> */}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '40px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >800 Loads/Month</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Document Storage</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Drivers</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Users</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Technical Support</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >New Feature Request</p>
            </div>
          </div>
        </div>

        {/* <div style={{
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
          padding: '20px',
          flex: 1
          }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h4 style={{}}>Gold Plan</h4>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: "center",
              alignItems: 'baseline'
            }}>
              <p style={{ fontSize: '2.5rem', fontWeight: '300' }}>$279</p>
              <h5>/mo</h5>
            </div>
            <p style={{ fontSize: '14px', marginBottom: '0px', fontWeight: 'bold' }}>2000 loads/month</p>
            <Button className="btn_light" type="submit" onClick={() => window.location.href = "https://buy.stripe.com/test_00g28xaLEglu3NSeUX"}>
              Get started
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '40px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >2000 Loads/Month</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Document Storage</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Drivers</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Unlimited Users</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >Technical Support</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
              <img src="img/green.png" alt="green" style={{ width: '20px', height: '20px' }} />
              <p style={{ fontWeight: '16px', marginBottom: '2px', fontSize: '14px' }} >New Feature Request</p>
            </div>


          </div>
        </div> */}
      </div >
    </>
  );
}

export default Upgrade;