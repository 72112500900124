import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../utils/auth-context";
import { Form, Formik } from "formik";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import axios from "../utils/axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const addMCNumberValidator = (values) => {
  const errors = {};

  if (values.carrierName === "") {
    errors.carrierName = "This field is required.";
  }

  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = pattern.test(values.email);

  if (values.email !== "" && !isValid) {
    errors.email = "Email is invalid.";
  }

  if (values.mcNumber === "") {
    errors.mcNumber = "This field is required.";
  }

  if (values.password === "") {
    errors.password = "This field is required.";
  } else if (values.password.length < 6) {
    errors.password = "Password is too short.";
  } else if (values.password.length > 16) {
    errors.password = "Password is too long.";
  }

  return errors;
};

const AdminDashboard = () => {
  const { token, user, logout } = useAuth();
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [campanyId, setCampanyId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleModal = () => setDeleteModal((prev) => !prev);

  if (user.role !== "admin") {
    history.push("/carrier-dashboard");
  }

  useEffect(() => {
    const getCompanies = async () => {
      await axios
        .get("/companies", {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((resp) => setCompanies(resp.data))
        .catch((err) => toast.error(err.response?.data?.message));
    };

    getCompanies();
  }, []);

  return (
    <>
      <Modal isOpen={deleteModal}>
        <ModalHeader
          toggle={() => {
            setCampanyId(null);
            toggleModal();
          }}
        >
          Delete Rate Con?
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete?</p>
          <div>
            <Button
              onClick={() => {
                setCampanyId(null);
                toggleModal();
              }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <Button
              onClick={async () => {
                await axios
                  .delete("/companies/" + campanyId, {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                  })
                  .then((resp) => {
                    setCompanies((prev) =>
                      prev.filter((itm) => itm.id !== campanyId)
                    );
                  })
                  .catch((err) => {
                    toast.error(err.response?.data?.message || err.message);
                  });
                setCampanyId(null);
                toggleModal();
              }}
              color="danger"
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <div className="p-3 py-5">
        <div
          style={{
            maxWidth: "900px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              position: "absolute",
              top: "20px",
              right: "50px",
            }}
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            <i className="fa-solid fa-power-off"></i> Logout
          </Link>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              position: "absolute",
              top: "20px",
              left: "50px",
            }}
          >
            <span className="fa-solid fa-arrow-left"></span> Go Back
          </Link>
          <h3 className="text-center mb-5" style={{ fontWeight: "bold" }}>
            Add Carrier
          </h3>
          <Formik
            initialValues={{
              carrierName: "",
              email: "",
              phone: "",
              mcNumber: "",
              password: "",
            }}
            validate={addMCNumberValidator}
            onSubmit={async (values) => {
              await axios
                .post("/companies", values, {
                  headers: {
                    Authorization: "Token " + token,
                  },
                })
                .then((resp) => setCompanies((prev) => [resp.data, ...prev]))
                .catch((err) => toast.error(err.response?.data?.message));
            }}
          >
            {({ handleSubmit, errors, getFieldProps }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="d-flex gap-3">
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Carrier Name"
                      className="form-ctrl"
                      {...getFieldProps("carrierName")}
                    />
                    {errors.carrierName && (
                      <small className="d-block text-danger text-center">
                        {errors.carrierName}
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Email"
                      type="text"
                      className="form-ctrl"
                      {...getFieldProps("email")}
                    />
                    {errors.email && (
                      <small className="d-block text-danger text-center">
                        {errors.email}
                      </small>
                    )}
                  </FormGroup>
                </div>
                <div className="d-flex gap-3">
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Phone Number"
                      className="form-ctrl"
                      {...getFieldProps("phone")}
                    />
                    {errors.phone && (
                      <small className="d-block text-danger text-center">
                        {errors.phone}
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      placeholder="MC Number"
                      className="form-ctrl"
                      {...getFieldProps("mcNumber")}
                    />
                    {errors.mcNumber && (
                      <small className="d-block text-danger text-center">
                        {errors.mcNumber}
                      </small>
                    )}
                  </FormGroup>
                </div>
                <div className="d-flex gap-3">
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Password"
                      type="password"
                      className="form-ctrl"
                      {...getFieldProps("password")}
                    />
                    {errors.password && (
                      <small className="d-block text-danger text-center">
                        {errors.password}
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      placeholder=""
                      type="password"
                      className="form-ctrl"
                      disabled
                      style={{ border: "none" }}
                    />
                  </FormGroup>
                </div>
                <div className="d-flex justify-content-center">
                  <Button type="submit" className="btn_light">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {companies?.length > 1 && (
            <Table className="mt-5">
              <thead>
                <tr>
                  <th>Carrier Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>MC Number</th>
                  <th>Signup at</th>
                  <th>Last Login</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {companies?.map(
                  (item, index) =>
                    !item.isAdmin && (
                      <tr key={index}>
                        <td>{item.carrierName}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.mcNumber}</td>
                        <td>{item.createdAt.split('T')[0]}</td>
                        <td>{item.lastLogin?.split('T')[0]}</td>
                        <td className="text-end">
                          <i
                            className="fa-sharp fa-solid fa-circle-xmark"
                            style={{
                              color: "#b32929",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCampanyId(item.id);
                              toggleModal();
                            }}
                          ></i>
                        </td>
                      </tr>
                    )

                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
