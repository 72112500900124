import React from "react";

const TrackerItem = ({ text, date, isCompleted }) => {
  return (
    <div className="tracker-item">
      {isCompleted ? (
        <div
          className="tracker-item-icon"
          style={{
            backgroundColor: "#67DC48",
          }}
        >
          <span className="fa-regular fa-circle-check"></span>
        </div>
      ) : (
        <div className="tracker-item-icon"></div>
      )}
      <div>
        <h6 className="mb-0" style={{ fontWeight: "bold" }}>
          {text}
        </h6>
        {isCompleted && <small className="mb-0">{date}</small>}
      </div>
    </div>
  );
};

export default TrackerItem;
