import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import axios from "../utils/axios";
import { useAuth } from "../utils/auth-context";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const carreirLoginValidator = (values) => {
  const errors = {};
  if (values.mcNumber === "") {
    errors.mcNumber = "This field is required.";
  }

  if (values.password === "") {
    errors.password = "This field is required.";
  } else if (values.password.length < 6) {
    errors.password = "Password is too short.";
  } else if (values.password.length > 16) {
    errors.password = "Password is to long.";
  }

  return errors;
};

const CarrierLogin = () => {
  const history = useHistory()
  const { login } = useAuth();

  return (
    <div className="p-3 py-5">
      <div
        style={{
          maxWidth: "300px",
          width: "100%",
          margin: "0px auto",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <i className="fa-solid fa-arrow-left"></i> Go Back
        </Link>
        <Formik
          initialValues={{ mcNumber: "", password: "" }}
          validate={carreirLoginValidator}
          onSubmit={async (values) => {
            await axios
              .post("/auth/carrier-login", values)
              .then((resp) => {
                login(resp.data);
              })
              .catch((err) => {
                toast.error(err.response?.data?.message);
              });
          }}
        >
          {({ handleSubmit, errors, getFieldProps }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  placeholder="MC Number"
                  className="form-ctrl"
                  {...getFieldProps("mcNumber")}
                />
                {errors.mcNumber && (
                  <small className="text-danger">{errors.mcNumber}</small>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Password"
                  className="form-ctrl"
                  {...getFieldProps("password")}
                />
                {errors.password && (
                  <small className="text-danger">{errors.password}</small>
                )}
              </FormGroup>
              <div className="d-flex justify-content-center">
                <Button className="btn_light" type="submit">
                  Login
                </Button>
              </div>

              {/* <p style={{ color: 'blue', textAlign: 'right', fontSize: '14px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { history.push("/carrier-reset-password") }}>Forgot Password?</p> */}

            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CarrierLogin;
