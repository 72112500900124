import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Main from "./screens/Main";
import AuthProvider from "./utils/auth-context";
import axios from "./utils/axios";
import { toast } from "react-toastify";

const App = () => {
  const [loading, set_loading] = useState(true)
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      const check_plan = async () => {
        try {
          const res = await axios.post('/auth/check_plan', {
            token
          })
          if (res.status === 200) {
            console.log(res.data.current, JSON.parse(localStorage.getItem('user')).user.plan)
            if (res.data.current === JSON.parse(localStorage.getItem('user')).user.plan) {
              set_loading(false)
            } else {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              set_loading(false)
              setTimeout(() => {
                toast.error("Subscription chnaged! Login to continue.");
              }, 500);
            }
          } else {
            set_loading(false)
          }
        } catch (error) {
          console.log(error)
          set_loading(false)
        }
      }
      check_plan()

    } else {
      set_loading(false)
    }
  }, [])
  return (
    <>
      {loading ?
        <p>Loading...</p>
        :
        <AuthProvider>
          <BrowserRouter>
            <Main />
            <ToastContainer position="top-right" hideProgressBar theme="colored" />
          </BrowserRouter>
        </AuthProvider>
      }
    </>
  );
};

export default App;
